export const environment = {
    firebaseConfig: {
        apiKey: "AIzaSyAEIUnA0Ez5gHO0Bj_gQ2BDpHDa5ASrIMA",
        authDomain: "gooks-staging.firebaseapp.com",
        databaseURL: "https://gooks-staging-default-rtdb.asia-southeast1.firebasedatabase.app",
        projectId: "gooks-staging",
        storageBucket: "gooks-staging.firebasestorage.app",
        messagingSenderId: "848318024536",
        appId: "1:848318024536:web:6d06478bdee5c7d32a332d",
        measurementId: "G-LQ05LVFX72"
    }
};