import { Component, inject } from '@angular/core';
import { Firestore, doc, docData, Timestamp } from '@angular/fire/firestore';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { NavigationEnd, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { filter, map, switchMap } from 'rxjs/operators';

interface Story {
  id: string;
  is_deleted: boolean;
  description: RichTextData;
  created_by?: User;
  links: [Link];
}

interface Link {
  id: string;
  is_paid?: boolean;
  story_id?: string;
  url?: string;
}

interface User {
  uid: string;
  email?: string;
  created_at: Timestamp;
}

interface Span {
  start: number;
  end: number;
  font_name?: string;
  font_size?: number;
  color?: string;
  is_bold?: boolean;
  is_italic?: boolean;
  is_underlined?: boolean;
  is_strikethrough?: boolean;
}

interface RichTextData {
  text: string;
  spans: Span[];
}

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  standalone: false,
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  link$: Observable<Link> | null = null;
  story$: Observable<Story> | null = null;
  sanitizedDescription$: Observable<SafeHtml> | null = null;
  private firestore: Firestore = inject(Firestore);

  constructor(private sanitizer: DomSanitizer, private router: Router) {
    this.router.events
    .pipe(
      filter((event) => event instanceof NavigationEnd),
      map(() => {
        const segments = this.router.url.split('/').filter(segment => segment);
        return segments.length > 0 ? segments[0] : null;
      }),
      filter((id) => !!id)
    )
    .subscribe((id) => {
      console.log('id: ', id)
      // getting link
      const linkRef = doc(this.firestore, `links/${id}`);
      this.link$ = docData(linkRef, { idField: 'id' });

      this.story$ = this.link$!.pipe(
        switchMap((link: any) => {
          if (!link || !link.story_id) {
            console.error('story_id not found in link:', link);
            return [];
          }
          const storyRef = doc(this.firestore, `stories/${link.story_id}`);
          return docData(storyRef, { idField: 'id' });
        })
      );

      this.sanitizedDescription$ = this.story$.pipe(
        map((story: any) => {
          if (!story || !story.description) {
            console.error('Description not found in story:', story);
            return '';
          }
          return this.toStyledHTML(story.description);
        })
      );
    });
  }

  private toStyledHTML(data: RichTextData): SafeHtml {
    let result = '';
    let cursor = 0;

    for (const span of data.spans) {
        // Add plain text before the span
        if (cursor < span.start) {
            const plainText = data.text.slice(cursor, span.start);
            result += plainText.replace(/\n/g, '<br>'); // Replace \n with <br>
        }

        // Add styled text for the span
        const styledText = data.text.slice(span.start, span.end).replace(/\n/g, '<br>'); // Replace \n with <br> inside spans
        const styles: string[] = [];

        if (span.color) styles.push(`color: ${span.color}`);

        // Handle font_name: extract base font and apply correct font-family
        if (span.font_name) {
            const baseFont = span.font_name.split('-')[0]; // Extract base font (e.g., "Lexend" from "Lexend-Bold")
            styles.push(`font-family: '${baseFont}', sans-serif`);
            if (span.font_name.includes('Bold')) styles.push('font-weight: bold');
            if (span.font_name.includes('Italic')) styles.push('font-style: italic');
        }

        if (span.font_size) styles.push(`font-size: ${span.font_size}px`);
        if (span.is_bold && !span.font_name?.includes('Bold')) styles.push('font-weight: bold');
        if (span.is_italic && !span.font_name?.includes('Italic')) styles.push('font-style: italic');
        if (span.is_underlined) styles.push('text-decoration: underline');
        if (span.is_strikethrough) styles.push('text-decoration: line-through');

        result += `<span style="${styles.join('; ')}">${styledText}</span>`;
        cursor = span.end;
    }

    // Add remaining text after the last span
    if (cursor < data.text.length) {
        const remainingText = data.text.slice(cursor);
        result += remainingText.replace(/\n/g, '<br>'); // Replace \n with <br>
    }

    // Sanitize and return the HTML
    return this.sanitizer.bypassSecurityTrustHtml(result);
  }
}